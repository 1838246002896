import React, { Component } from 'react';
import { Link } from 'gatsby';
import { Header, Layout } from '../components';
import FourOhFourImage from '../images/404.svg';
import * as styles from './404.module.scss';

const HoldingPage = props => {
  const { location } = props;
  return (
    <Layout location={location} hideCommonUi>
      <div className={`${styles.notFoundWrap} wrapper`}>
        <img className={styles.notFoundImage} src={FourOhFourImage} alt="Opps this page has gone missing" />
        <div className={styles.notFoundText}>
          <h2>We are working on something special. Check back soon.</h2>
        </div>
      </div>
    </Layout>
  );
};

export default HoldingPage;
